<template>
  <div class="business-solutions">
    <Hero heading="Electrify America Business Solutions">
      <template slot="image">
        <picture>
          <source
            media="(min-width: 950px )"
            srcset="
              @/assets/images/BusinessSolutions/business-solutions-hero.png,
              @/assets/images/BusinessSolutions/business-solutions-hero@2x.webp 2x
            "
          />
          <source
            media="(min-width: 576px )"
            srcset="
              @/assets/images/BusinessSolutions/business-solutions-hero-tablet.webp,
              @/assets/images/BusinessSolutions/business-solutions-hero-tablet@2x.webp 2x
            "
          />
          <img
            srcset="
              @/assets/images/BusinessSolutions/business-solutions-hero-mobile.png,
              @/assets/images/BusinessSolutions/business-solutions-hero-mobile@2x.webp 2x
            "
            alt="Two Electrify America charging stations under a canopy"
          />
        </picture>
      </template>
      <h2>Customized Solutions to Meet Your Needs</h2>
      <p>
        Electrify America is the largest open Hyper-Fast network in the U.S. and collaborates with major corporations to
        promote electric vehicle awareness across the nation. We manage the entire electrification process — from
        initial planning to ongoing maintenance — so you don’t have to.
      </p>
    </Hero>
    <div class="wrapper">
      <section class="l-one__col l-one__col--left-align l-one__col--no-side-padding">
        <h2>Your business, electrified by experts</h2>
        <p>
          When you work with Electrify America, you leverage experience from trusted industry-leaders who’ve earned the
          “<a class="link medium" href="https://media.electrifyamerica.com/en-us/releases/167" target="_blank"
            >EV Charging Infrastructure Best-in-Test</a
          >” award, two years in a row, for 2020 and 2021. From site acquisition to energy management, we’ve got you
          covered.
        </p>
      </section>
    </div>
    <div class="wrapper">
      <section class="grid-3 grid-equalHeight">
        <div class="column grid-column-equalHeight">
          <section class="content">
            <div class="text">
              <h3 class="headline4">Public Electrify America station hosting</h3>
              <p>
                Join leaders in <strong>convenience</strong>, <strong>retail</strong>, and <strong>groceries</strong> on
                the Electrify America network at no cost.
              </p>
            </div>
            <a href="https://www.electrifyamerica.com/real-estate/" target="_blank" class="button">Host a station</a>
            <DecorativeList>
              <li>Electrify America pays 100% of the upfront capital investment</li>
              <li>Become an industry leader supporting clean energy</li>
            </DecorativeList>
            <picture @click="routingEvent('https://www.electrifyamerica.com/real-estate/')">
              <source
                media="(min-width: 994px )"
                srcset="
                  @/assets/images/BusinessSolutions/business-solutions-card1.png,
                  @/assets/images/BusinessSolutions/business-solutions-card1@2x.png 2x
                "
              />
              <img
                src="@/assets/images/BusinessSolutions/business-solutions-card1.png"
                alt="Electric vehicles charging at Electrify America stations"
              />
            </picture>
          </section>
        </div>
        <div class="column grid-column-equalHeight">
          <section class="content">
            <div class="text">
              <h3 class="headline4">Public-access station ownership</h3>
              <p>
                Own a branded network like top <strong>convenience</strong>, <strong>retail</strong>, and
                <strong>utility providers</strong>.
              </p>
            </div>
            <a
              target="_blank"
              :href="
                isQa
                  ? 'https://elco-qa.electrify-commercial.com/contact-us/?source=own-a-network'
                  : 'https://electrify-commercial.com/contact-us/?source=own-a-network'
              "
              class="button"
              >Own a NETWORK</a
            >
            <DecorativeList>
              <li>Station branding and pricing control</li>
              <li>Equipment ownership</li>
              <li>Custom site design to accommodate your program needs</li>
              <li>NEVI and applicable Federal, State, and Local Grant funding application support</li>
            </DecorativeList>
            <picture @click="routingEvent('https://electrify-commercial.com/contact-us/')">
              <source
                media="(min-width: 769px )"
                srcset="
                  @/assets/images/BusinessSolutions/business-solutions-card2.png,
                  @/assets/images/BusinessSolutions/business-solutions-card2@2x.png 2x
                "
              />
              <img
                src="@/assets/images/BusinessSolutions/business-solutions-card2.png"
                alt="An Electrify America Commercial charger at a Costco"
              />
            </picture>
          </section>
        </div>
        <div class="column grid-column-equalHeight">
          <section class="content mb-0">
            <div class="text">
              <h3 class="headline4">Private-access station ownership</h3>
              <p>
                We guide <strong>medium</strong> and <strong>heavy-duty fleet operators</strong> in electrification and
                becoming clean energy leaders.
              </p>
            </div>
            <a
              target="_blank"
              :href="
                isQa
                  ? 'https://elco-qa.electrify-commercial.com/contact-us/?source=fleet-charging'
                  : 'https://electrify-commercial.com/contact-us/?source=fleet-charging'
              "
              class="button"
              >Fleet charging solutions</a
            >
            <DecorativeList>
              <li>Behind the fence or public facing</li>
              <li>Equipment ownership</li>
              <li>Custom layouts to accommodate a variety of vehicle sizes</li>
              <li>Grant funding guidance</li>
            </DecorativeList>
            <picture @click="routingEvent('https://electrify-commercial.com/contact-us/')">
              <source
                media="(min-width: 994px )"
                srcset="
                  @/assets/images/BusinessSolutions/business-solutions-card3.webp,
                  @/assets/images/BusinessSolutions/business-solutions-card3@2x.webp 2x
                "
              />
              <img
                src="@/assets/images/BusinessSolutions/business-solutions-card3.webp"
                alt="A 41 Volvo VNR truck next to a commercial charger "
              />
            </picture>
          </section>
        </div>
      </section>
    </div>
    <div class="wrapper">
      <section class="l-one__col l-one__col--left-align l-one__col--no-side-padding extra-margin">
        <h2>Industry specific solutions</h2>
        <p>
          Over 180 of the country’s most influential brands trust Electrify America to deliver innovative charging
          solutions for their businesses.
        </p>
      </section>
      <section class="grid-2_md-2_xs-1">
        <div class="column">
          <img src="@/assets/images/BusinessSolutions/shopping-bag.svg/?external" alt="" />
          <h3 class="headline4">For retail</h3>
          <p>
            Electrify America pays 100% of the upfront investment to design and install new Electrify America charging
            stations, as well as maintenance and upkeep costs.
          </p>
        </div>
        <div class="column">
          <img src="@/assets/images/BusinessSolutions/shopping-cart.svg/?external" alt="" />
          <h3 class="headline4">For convenience</h3>
          <p>
            Transform the future of retail by becoming a one-stop shop for customers to dine, shop, and charge — all in
            one place.
          </p>
        </div>
        <div class="column">
          <img src="@/assets/images/BusinessSolutions/truck.svg/?external" alt="" />
          <h3 class="headline4">For fleet</h3>
          <p>
            Electrify America provides customized solutions to bring electric vehicle charging technology and zero
            emissions vehicles together.
          </p>
        </div>
        <div class="column">
          <img src="@/assets/images/BusinessSolutions/dark.svg/?external" alt="" />
          <h3 class="headline4">For utilities</h3>
          <p>
            Electrify Commercial is proud to collaborate with energy providers. From execution with program design to
            maintenance, we handle projects from start to finish under your brand.
          </p>
        </div>
      </section>
    </div>
    <div class="wrapper p-0">
      <Card
        :bgColor="true"
        :isReverse="true"
        heading="Up to 80% of any build costs could be covered by NEVI"
        headlineLevel="h2"
        :maxWidth="1200"
      >
        <template #image>
          <picture>
            <source
              media="(min-width: 993px)"
              srcset="
                @/assets/images/BusinessSolutions/business-solutions-secondary-hero.png,
                @/assets/images/BusinessSolutions/business-solutions-secondary-hero@2x.webp 2x
              "
            />
            <source
              media="(min-width: 576px )"
              srcset="
                @/assets/images/BusinessSolutions/business-solutions-secondary-hero-tablet.webp,
                @/assets/images/BusinessSolutions/business-solutions-secondary-hero-tablet@2x.webp 2x
              "
            />
            <img
              srcset="
                @/assets/images/BusinessSolutions/business-solutions-secondary-hero-mobile.webp,
                @/assets/images/BusinessSolutions/business-solutions-secondary-hero-mobile@2x.webp 2x
              "
              alt=""
              @click="routingEvent('https://www.fhwa.dot.gov/bipartisan-infrastructure-law/nevi_formula_program.cfm')"
            />
          </picture>
        </template>
        <p>
          The NEVI Formula Program provides $5 billion funding to all 50 states and territories over five years to
          encourage states to build EV charging station networks along highway corridors, paving the way for more
          Americans to enjoy the benefits of EV driving.
        </p>
        <a class="button-link" target="_blank" href="https://electrify-commercial.com/NEVI"
          >Learn more about Nevi funding<arrow class="arrow"></arrow
        ></a>
      </Card>
    </div>
  </div>
</template>

<script>
import Hero from '@/components/Hero/Hero.vue';
import DecorativeList from '@/components/DecorativeList/DecorativeList.vue';
// import Carousel from '@/components/Carousel/Carousel.vue';
import Card from '@/components/Card/Card.vue';
import arrow from '@/assets/images/Global/arrow.svg';

export default {
  metaInfo: {
    title: 'Business Solutions | Electrify America',
    meta: [
      {
        name: 'description',
        content:
          'Electrify America is the largest public fast charging network in the U.S. and collaborates with major corporations to promote electric vehicle awareness across the nation.',
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/business-solutions/' }],
  },
  components: { Hero, DecorativeList, Card, arrow },
  data() {
    return {
      isQa: process.env.VUE_APP_ENV === 'qa',
    };
  },
  computed: {
    isMobile() {
      return this.$resize && this.$mq.below(994);
    },
    isSmall() {
      return this.$resize && this.$mq.below(769);
    },
    isExtraSmall() {
      return this.$resize && this.$mq.below(576);
    },
  },
  methods: {
    routingEvent(url) {
      window.open(url, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.business-solutions {
  h2 {
    font-weight: $fw-light;
  }
  .headline4 {
    font-weight: $fw-medium;
    font-size: $global-font-size * 1.27;
  }

  ::v-deep .hero {
    &__text section {
      padding-top: 32px;
      h3 {
        font-weight: $fw-medium;
        font-size: 1.5rem;
      }
      @media (min-width: 750px) {
        max-width: 992px;
        display: flex;
        h3 {
          flex: 0.9;
          font-size: 2.1rem;
        }
        p {
          flex: 1.5;
          padding-left: 40px;
        }
      }
    }
  }
  ::v-deep .decorative-list {
    @media (min-width: $bp_medium_min) {
      height: 220px;
    }
    @media (min-width: 1080px) {
      height: 170px;
    }
    li {
      background-image: url('~@/assets/images/BusinessSolutions/checkmark.svg?external');
      padding-left: 1.75rem;
      font-weight: $fw-medium;
    }
  }
  ::v-deep .card {
    border-radius: 8px;
    @media (min-width: 768px) {
      margin: 0 auto;
      margin-top: 100px;
      margin-bottom: 80px;
      max-width: $max_width;
    }
    &__container {
      flex-direction: column;
      @media (min-width: $bp_large_min) {
        flex-direction: row;
      }
    }
    h2 {
      font-weight: $fw-medium;
    }
    &__column {
      max-width: none;
      @media (min-width: $bp_large_min) {
        max-width: 502px;
      }
    }
    img {
      max-width: 100%;
      border-radius: $global-border-radius;
      @media (min-width: $bp_large_min) {
        max-width: 360px;
      }
      &:hover {
        box-shadow: 0 20px 15px -2px #ccc;
        transition: box-shadow 0.1s ease-in-out;
      }
    }
  }

  .wrapper {
    @media (min-width: $bp_large_min) {
      padding: 0 $spacing-unit * 8;
    }
  }

  .grid-3 {
    @media (max-width: $bp_medium_min) {
      flex-direction: column;
      align-content: center;
    }
    .column {
      padding: 0 $spacing-unit * 1.5;
      @media (max-width: $bp_medium_min) {
        max-width: 700px !important;
      }
      .content {
        padding: $spacing-unit * 2;
        border-radius: 8px;
        box-shadow: 0 8px 24px 0 rgba(10, 18, 74, 0.1);
        border-top: 8px solid $c-primary;
        @media (max-width: $bp_medium_min) {
          max-width: 700px !important;
          margin-bottom: 40px;
        }
        .text {
          height: 164px;
        }
      }
    }
    img {
      width: 100%;
      margin-bottom: $global-spacing;
      border-radius: $global-border-radius;
      &:hover {
        box-shadow: 0 20px 15px -2px #ccc;
        transition: box-shadow 0.1s ease-in-out;
      }
    }
  }

  .grid-4_md-2_xs-1 {
    margin-bottom: $global-spacing * 4;
  }

  .button-link {
    margin-bottom: 42px;
  }
  .button {
    box-sizing: border-box;
    width: 100%;
    margin-top: 0;
    margin-bottom: 24px;
    padding: 1.28em 1rem;
  }
  .l-one__col {
    margin-top: $spacing-unit * 8;
    margin-bottom: $spacing-unit * 5;
  }
  .l-one__col.extra-margin {
    margin-top: $spacing-unit * 16;
    margin-bottom: $spacing-unit * 5;
  }
  .carousel {
    p {
      min-height: 96px;
    }
  }
  .decorative-list {
    font-size: 14px;
  }
  .mb-0 {
    margin-bottom: 0px;
  }
  @media (max-width: $bp_medium_min) {
    .p-0 {
      padding: 0;
    }
    .card {
      border-radius: 0px !important;
    }
  }
}
</style>
